/*-----Global Componentns-----*/
.swiperItem__container {
	display: flex;
	flex: 0.33;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/*-----Images sizing, positioning-----*/
.swiperItem__containerImg {
	position: relative;
	padding: 5%;
}

.swiperItem__containerImg > img {
	width: 100%;
	max-width: 334px;
	max-height: 310px;
	position: relative;
	z-index: 10;
}

/*-----h1,h2 positioning + sizing-----*/
.swiperItem__containerText {
	width: 65%;
	cursor: pointer;
}
/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url("../../assets/fonts/Raleway-VariableFont_wght.ttf");
}
@font-face {
	font-family: Playfair Display;
	src: url("../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

.swiperItem__containerText > h4 {
	font-weight: bold;
	font-family: "Raleway", serif;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #5a8181;

	padding-top: 0.8rem;
}

.swiperItem__containerText > h1 {
	font-weight: medium;
	font-family: "Playfair Display", serif;
	font-size: 2rem;
	line-height: 1.3;
	color: #00001d;
	padding-top: 1.4rem;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	.swiperItem__containerText > h1 {
		font-size: 1.7rem;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.swiperItem__backImg {
		display: none;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.swiperItem__backImg {
		display: flex;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
