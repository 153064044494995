body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.container {
	transition: opacity 1s ease-in;
	opacity: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/*-----Navbar-----*/
.app__navbar {
	display: flex;
	flex-direction: row;
	width: 88%;
	justify-content: space-between;
	align-items: center;
	padding: 3rem 0 3rem 0;
}

.app__navbarLeft {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.app__navbarLeft > h3 {
	color: black;
	font-weight: regular;
	font-family: "Playfair Display", serif;
	font-size: 2rem;
	padding: 0 0 0.5rem 1rem;
	align-self: flex-end;
}

.app__navbarLeft > img {
	height: 3.5rem;
}

.app__navbarColor {
	color: #628989;
}

.app__navbarRight > img {
	cursor: pointer;
	z-index: 100;
}

.app__navbarRight > img:active {
	transform: scale(0.9);
}

.app__navbarExpandedOpacity {
	transition: 0.5s ease-in;
	position: fixed;
	display: none;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 10;
	opacity: 0;
}

.app__navbarExpandedContainer {
	transition: 0.6s ease-in;
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 25rem;
	height: 100vh;
	border-left: 1.5rem solid #628989;
	background-color: #ffffff;
	transform: translateX(100%);
	z-index: 110;
}

.app__navbarExpandedItems {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 65%;
	height: 70%;
}

.app__navbarExpandedItems > img {
	padding: 0.5rem;
	margin-top: 2.5rem;
	align-self: flex-end;
	cursor: pointer;
	transform: scale(1);
	transition: all 0.3s ease;
}

.app__navbarUl {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	height: 50%;
}

.app__navbarItem {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "Raleway", serif;
	letter-spacing: 2px;
	cursor: pointer;
	text-align: left;
	transform: scale(1);
	color: #00001d;
	text-decoration: none;
	padding: 20px;

	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: all 0.3s ease;
}

.app__navbarItem.active {
	color: #628989;
	background: none;
}

/*-----Navbar Animation-----*/
.app__navbarItem:hover,
.app__navbarExpandedItems > img:hover {
	transform: scale(1.1);
}

.app__navbarItem:active,
.app__navbarExpandedItems > img:active {
	transform: scale(0.9);
}

/*-----Happy footer-----*/
.home__footer {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	width: 100%;
	height: 20vh;
	background-color: #01011d;
	z-index: 900;
}

.home__footerLeft {
	display: flex;
	flex: 0.5 1;
	background-image: url(/static/media/FooterBG.c868add4.svg);
}

.home__footerRight {
	display: flex;
	flex: 0.5 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #01011d;
	z-index: 900;
}

.home__footerRight > div > h3 {
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
	font-weight: bold;
	letter-spacing: 1px;
	text-align: center;
	color: white;
	text-transform: uppercase;
}

.home__footerRight > div > h1 {
	font-family: "Playfair Display", serif;
	color: #c78567;
	font-size: 1.8rem;
	margin-top: 5px;
	padding-bottom: 2rem;
	text-align: center;
}

.home__footerRight > div > h5 {
	font-family: "Playfair Display", serif;
	color: white;
	text-align: center;
	font-size: 0.6rem;
	letter-spacing: 1px;
}

.home__footerRight > div:nth-child(2) > h5 {
	margin-top: 4px;
}

/*-----Scroll Arrow-----*/
.app__arrow {
	position: fixed;
	bottom: 50px;
	right: -100px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center;
	transition: .4s;
	transform: scale(1);
	background-color: rgba(197, 197, 197, .0);
	cursor: pointer;
	z-index: 500;
}

.app__arrow > img {
	transform: translate(22%, 60%);
}

.app__arrow:hover {
	transform: scale(1.1);
	background-color: rgba(197, 197, 197, .4);
}

.app__arrow:active {
	transform: scale(.9);
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Upper container ****/
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Upper container ****/
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Happy Footer ****/
	.home__footerLeft {
		display: none;
	}
	.home__footerRight {
		flex: 1 1;
		flex-direction: row;
		justify-content: space-around;
	}

	.home__footerRight > div:nth-child(2) {
		margin-top: -25px;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Navbar ****/
	.app__navbarExpandedContainer {
		width: calc(100vw - 1.5rem);
	}
	.app__navbarExpandedItems {
		width: 80%;
	}
	.app__navbarExpandedItems > ul {
		align-items: center;
	}

	/**** Happy Footer ****/
	.home__footer {
		height: 22vh;
	}
	.home__footerLeft {
		display: none;
	}
	.home__footerRight {
		flex-direction: column;
		justify-content: center;
	}
	.home__footerRight > div > h1 {
		padding-bottom: 1.2rem;
	}

	.app__arrow {
		bottom: 10px;
		transform: scale(.75) !important;
	}
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.blog__container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

/*-----Upper container-----*/
.blog__upperCont {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	align-items: flex-start;
	align-self: flex-end;
	justify-content: space-between;
	height: calc(100vh - 152px);
	width: 94%;
}

.blog__upperImg {
	display: flex;
	flex: 0.5 1;
	align-items: flex-start;
	justify-content: flex-end;
	height: 100%;
	background-color: #5a8181;
}

.blog__upperImg > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

/*----- Description styling -----*/
.blog__description {
	display: flex;
	flex: 0.4 1;
	flex-direction: column;
	align-items: flex-start;
}

.blog__description > h3 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #5a8181;
	margin-top: 5vh;
}

.blog__description > h1 {
	font-size: 4rem;
	font-weight: medium;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	text-align: left;
	color: #00001d;
	margin: 6vh 0 8vh 0;
}

.blog__descriptionAuthorDate {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.blog__descriptionAuthorDate > h5 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #c9c9c9;
}

.blog__descriptionAuthorDate > h4 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	letter-spacing: 2px;
	color: #5a8181;
}

.blog__line {
	background-color: #5a8181;
	height: 2px;
	width: 2rem;
	margin: 0 2rem 0 2rem;
}

/*----- Lower container -----*/
.blog__lowerCont {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	height: auto;
}

/*-----Posts list section-----*/
.blog__postsListCont {
	display: flex;
	flex: 0.3 1;
	flex-direction: column;
	align-items: center;
	background-image: url(/static/media/BlogBG.c71fa339.svg);
}

.blog__postsList {
	width: 70%;
	margin-top: 5vh;
}

.blog__postsList > h3 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #5a8181;
}

.blog__postsListBorder {
	padding-bottom: 1.6rem;
	border-bottom: 2px solid #a3a3a3;
}

.blog__postsListItem {
	margin-top: 1.2rem;
	padding-left: 0.8rem;
	padding-right: 1.2rem;
	cursor: pointer;
	transition: all 0.3s ease;
}

.blog__postsListItem:active {
	transform: scale(.9);
}

.blog__postsListItem > h5 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #5a8181;
	margin-bottom: 0.6rem;
}

.blog__postsListItem > h4 {
	font-size: 1.2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 1px;
	text-align: left;
	color: #00001d;
}

/*-----Post text-----*/
.blog__postText {
	display: flex;
	flex: 0.6 1;
	flex-direction: column;
	align-items: flex-start;
	margin: 10vh 0 20vh 10vh;
}

.blog__postText > p {
	font-size: 1.2rem;
	font-weight: bolder;
	font-family: "Playfair Display", serif;
	line-height: 1.6;
	letter-spacing: 2px;
	text-align: left;
	color: #00001d;
	margin-bottom: 1.6rem;
}

.blog__postText > p:first-child:first-letter {
	float: left;
	font-size: 500%;
	line-height: 0;
	padding: 0 10px 30px 0;
	margin-top: -15px;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Upper container ****/
	.blog__description > h1 {
		font-size: 3rem;
		margin: 3vh 0 5vh 0;
	}

	/**** lower container ****/
	.blog__postsListItem > h4,
	.blog__postText > p {
		font-size: 1rem;
	}
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Upper container ****/
	.blog__description > h1 {
		font-size: 2.5rem;
		margin: 2vh 0 3vh 0;
	}
	.blog__description h4,
	.blog__description h3 {
		font-size: 0.8rem;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Upper container ****/
	.blog__upperCont {
		flex-direction: column-reverse;
		align-self: center;
		width: 88%;
		height: auto;
	}
	.blog__upperImg {
		width: 100%;
	}
	.blog__description > h1 {
		margin: 2vh 0 4vh 0;
	}
	.blog__descriptionAuthorDate {
		justify-content: flex-end;
	}

	/**** Lower container ****/
	.blog__lowerCont {
		flex-direction: column-reverse;
	}
	.blog__postText {
		margin: 10vh 0 5vh 0;
		width: 88%;
		align-self: center;
	}
	.blog__postsList {
		width: 80%;
		padding-bottom: 10vh;
	}
	.blog__postsList > h3 {
		line-height: 1.5;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Upper container ****/
	.blog__description > h1 {
		font-size: 2rem;
	}
	.blog__postText {
		width: 82%;
	}
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.post__continer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	width: 100%;
	align-self: center;
	justify-self: center;
}

/*-----Direct Styling-----*/
.post__continer > h1 {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "Raleway", serif;
	letter-spacing: 2px;
	text-align: center;
	color: #00001d;
	line-height: 1.6;
	margin-bottom: 2vh;
}

.post__continer > h3 {
	font-size: 1rem;
	font-weight: bolder;
	font-family: "Raleway", serif;
	letter-spacing: 2px;
	text-align: center;
	color: #00001d;
	margin-bottom: 2vh;
	line-height: 1.6;
}

.post__continer > h4 {
	font-size: 1rem;
	font-weight: bolder;
	font-family: "Playfair Display", serif;
	letter-spacing: 2px;
	text-align: center;
	color: #5a8181;
	border-bottom: 1px solid #5a8181;
	padding-bottom: 1rem;
	width: 100%;
	margin-bottom: 2vh;
}

.post__continer > p {
	font-size: 0.8rem;
	font-family: "Raleway", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	text-align: center;
	color: #00001d;
	margin-bottom: 2vh;
}

.post__continer > img {
	width: 100%;
	max-width: 334px;
	max-height: 310px;
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.createpost__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 152px);
	width: 100%;
	background-image: url(/static/media/FormBG.19dcfe13.svg);
	background-repeat: no-repeat;
	background-size: cover;
}

.createpost__containerLoggedIn {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	background-image: url(/static/media/FindMoreBG.4b2961d2.svg);
}

/*-----Sign in-----*/
.createpost__signIn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #ffffff;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
	border: 1px solid #eeeeee;
	height: 50vh;
	width: 50%;
	border: 10%;
}

.createpost__signIn > form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 60%;
	height: 60%;
}

.createpost__signIn > form > * {
	padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #eeeeee;
	resize: none;
	width: 100%;
}

.createpost__signIn > form > *:nth-child(4) {
	width: 50%;
}

.createpost__signIn > form > *:first-child {
	border: none;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: 2px;
	text-transform: uppercase;
}

/*-----Form container-----*/
.continerLoggedIn__form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60%;
	min-height: 80vh;
	height: auto;
	margin-top: 2vh;
	background-color: #ffffff;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
	border: 1px solid #eeeeee;
}

.continerLoggedIn__form > form {
	display: flex;
	margin: 4vh 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 80%;
	height: auto;
}

.continerLoggedIn__form > form > * {
	padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #eeeeee;
	resize: none;
	width: calc(100% - 3rem);
	margin-bottom: 3vh;
}

.continerLoggedIn__form > form > *:first-child {
	border: none;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.continerLoggedIn__form > form > *:nth-child(6) {
	height: 25vh;
}

.continerLoggedIn__form > form > *:nth-child(7) {
	border: none;
}

.continerLoggedIn__form > form > *:last-child {
	width: 50%;
}

/*-----Posts container-----*/
.posts__containerGrid {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 90%;
	margin: 8vh 0;
	height: auto;
}

.continerLoggedIn__posts {
	display: grid;
	grid-template-columns: auto auto;
	grid-gap: 3rem;
	gap: 3rem;
}

.posts__onePost {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	padding: 3rem 3rem 9rem 3rem;
	background-color: #ffffff;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
	border: 1px solid #eeeeee;
}

.posts__onePost > button {
	position: absolute;
	z-index: 20;
	align-self: center;
	padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	border: 1px solid #eeeeee;
	bottom: 3rem;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	.createpost__signIn {
		margin-top: -20%;
	}

	/**** sign in ****/
	.createpost__signIn {
		width: 80%;
	}

	/**** grid template edit to one column ****/
	.continerLoggedIn__posts {
		grid-template-columns: auto;
	}
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** width of form to uploading ****/
	.continerLoggedIn__form {
		width: 80%;
		height: auto;
		padding: 4vh 0;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** sign in ****/
	.createpost__signIn > form > * {
		margin-top: 10px;
		font-size: 0.8rem;
	}
	.createpost__signIn > form {
		height: 80%;
	}
	.createpost__signIn > form > *:nth-child(4) {
		width: 80%;
	}
	.createpost__signIn > form > *:first-child {
		font-size: 1.5rem;
		padding: 0;
		margin-bottom: 1.5rem;
	}

	/**** width of form to uploading ****/
	.continerLoggedIn__form {
		width: 90%;
	}
	.continerLoggedIn__form > form > *:first-child {
		font-size: 1.6rem;
	}
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.f_a_t__containerFluid {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

/*-----Welcome Section-----*/
.f_a_t__welcomeSection {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: calc(100vh - 152px);
	background-image: url(/static/media/WaterMark.08a569c6.svg);
	background-size: cover;
	z-index: 1;
}

.f_a_t__welcomeSectionImg {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 88%;
	height: 65vh;
	margin-top: 5vh;
	z-index: 10;
}

.f_a_t__welcomeSectionText {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.f_a_t__welcomeSectionImg > img {
	position: absolute;
	bottom: -4rem;
	left: -3rem;
	z-index: -50;
}

.f_a_t__welcomeSectionText > h1 {
	font-size: 6em;
	font-family: "Playfair Display", serif;
	line-height: 2;
	letter-spacing: 2px;
	color: white;
	margin: 5% 0 0 calc(25% - 10px);
}

.f_a_t__welcomeSectionTextH2 {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 25%;
}

.f_a_t__welcomeSectionTextH2 > h2 {
	font-size: 1.2rem;
	letter-spacing: 3px;
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	color: white;
	margin-left: 1vw;
	line-height: 1.4;
	text-align: center;
}

.f_a_t__welcomeSectionDash {
	height: 4px;
	width: 1.5vw;
	align-self: center;
	background-color: #628989;
}

/*-----Quotation section-----*/
.f_a_t__quoteSection {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 88%;
	min-height: 20vh;
	margin-bottom: 10vh;
}

.f_a_t__quoteSection > img {
	display: flex;
	flex: 0.2 1;
}

.f_a_t__quoteSection > h1 {
	display: flex;
	flex: 0.7 1;
	flex-direction: column;
	font-size: 3.4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	margin-top: 2vh;
}

.f_a_t__quoteSectionTextEdit {
	color: #628989;
	margin-left: auto;
	text-align: center;
}

/*-----f_a_t Items section-----*/
.f_a_t__itemsSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 45vh;
	width: 100%;
	background-image: url(/static/media/WaterMark.08a569c6.svg);
	background-size: cover;
}

.f_a_t__itemsSectionContainer {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: 78%;
}

.f_a_t__breakRow {
	display: none;
}

.f_a_t__itemsSectionItem {
	display: flex;
	flex: 0.18 1;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: 35vh;
}

.f_a_t__itemsSectionItem > img {
	min-height: 53px;
	max-height: 54px;
	margin-bottom: 2vh;
}

.f_a_t__itemsSectionItem > h1 {
	text-align: left;
	font-size: 1.2rem;
	font-family: "Raleway", sans-serif;
	font-weight: bolder;
	letter-spacing: 2px;
	color: #00001d;
}

.f_a_t__itemsSectionItem > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	margin-top: 2vh;
}

/*-----Introduction Section-----*/
.f_a_t__introduction {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	min-height: 50vh;
	width: 88%;
	margin: 25vh 0 10vh 0;
}

.f_a_t__introductionContianer {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-between;
	max-width: 85%;
	min-height: 40vh;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

.f_a_t__introductionContianer > img {
	display: flex;
	flex: 0.2 1;
	margin: -14% 0 0 -16%;
	object-fit: contain;
}

.f_a_t__introductionDescription {
	display: flex;
	flex: 0.8 1;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	padding: 3rem;
}

.f_a_t__introductionDescription > h1 {
	text-align: left;
	font-size: 2.4rem;
	font-family: "Playfair Display", serif;
	letter-spacing: 2px;
	line-height: 2;
	color: #00001d;
}

.f_a_t__introductionDescription > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.4;
	font-size: 0.9rem;
	text-align: left;
	color: #7e7b7b;
}

/*-----Media querrys-----*/
/* Only for quoteSection Heading */
@media (max-width: 1380px) {
	/**** QuoteSection text ****/
	.f_a_t__quoteSection > h1 {
		flex: 0.76 1;
		font-size: 3rem;
	}
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Text in Welcome section ****/
	.f_a_t__welcomeSectionText > h1 {
		font-size: 5rem;
	}
	.f_a_t__welcomeSectionTextH2 > h2 {
		font-size: 1rem;
	}

	/**** QuoteSection text ****/
	.f_a_t__quoteSection > h1 {
		font-size: 2.5rem;
		margin-top: 0;
		align-self: center;
	}

	/**** f_a_t items section ****/
	.f_a_t__itemsSectionItem {
		flex: 0.22 1;
		align-items: center;
		height: 28vh;
	}
	.f_a_t__itemsSectionItem {
		text-align: center;
	}

	/**** Introduction Section ****/
	.f_a_t__introduction {
		margin: 5vh 0 5vh 0;
	}
	.f_a_t__introductionContianer > img {
		max-width: 50%;
		height: auto;
		margin: 0 0 0 -16%;
	}
	.f_a_t__introductionDescription {
		padding: 2rem 3rem;
	}
	.f_a_t__introductionDescription > p {
		font-size: 0.8rem;
	}
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Text in Welcome section ****/
	.f_a_t__welcomeSectionText > h1 {
		font-size: 4rem;
	}
	.f_a_t__welcomeSectionTextH2 > h2 {
		font-size: 0.8rem;
	}
	.f_a_t__welcomeSectionDash {
		height: 3px;
	}
	/**** Border in Welcome section ****/
	.f_a_t__welcomeSectionImg > img {
		bottom: -2rem;
		left: -1.5rem;
	}

	/**** QuoteSection text ****/
	.f_a_t__quoteSection > h1 {
		font-size: 2rem;
		text-align: center;
	}
	.f_a_t__quoteSectionTextEdit {
		margin-left: 0;
	}

	/**** f_a_t items section ****/
	.f_a_t__itemsSectionItem {
		flex: 1 1;
		height: 20vh;
		margin: 3rem 0 3rem 0;
	}
	.f_a_t__itemsSectionItem:nth-child(1),
	.f_a_t__itemsSectionItem:nth-child(4) {
		margin-right: 2rem;
	}
	.f_a_t__itemsSectionItem:nth-child(2),
	.f_a_t__itemsSectionItem:nth-child(5) {
		margin-left: 2rem;
	}
	.f_a_t__itemsSectionContainer {
		flex-wrap: wrap;
	}
	.f_a_t__breakRow {
		display: flex;
		height: 0;
		flex-basis: 100%;
	}

	/**** Introduction Section ****/
	.f_a_t__introduction {
		flex-direction: column;
		margin: 10vh 0 15vh 0;
		width: 100%;
	}
	.f_a_t__introductionContianer {
		flex-direction: column-reverse;
		align-items: center;
	}
	.f_a_t__introductionContianer > img {
		max-width: 75%;
		height: auto;
		margin: 0 0 -13% 0;
	}
	.f_a_t__introductionDescription > p,
	.f_a_t__introductionDescription > h1 {
		width: 100%;
		text-align: center;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Text in Welcome section ****/
	.f_a_t__welcomeSectionText {
		transform: rotateY(180deg);
	}
	.f_a_t__welcomeSectionText > h1 {
		font-size: 3rem;
		margin: 5% 0 0 0;
		transform: rotateY(180deg);
	}
	.f_a_t__welcomeSectionTextH2 {
		margin: 0;
		transform: rotateY(180deg);
	}
	/**** Border in Welcome section ****/
	.f_a_t__welcomeSectionImg > img {
		display: none;
	}

	/**** QuoteSection text ****/
	.f_a_t__quoteSection {
		flex-direction: column;
		justify-content: flex-end;
	}
	.f_a_t__quoteSection > h1 {
		flex: 0.2 1;
	}
	.f_a_t__quoteSection > img {
		display: none;
	}

	/**** f_a_t items section ****/
	.f_a_t__itemsSectionItem {
		height: 25vh;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Text in Welcome section ****/
	.f_a_t__welcomeSectionText > h1 {
		font-size: 1.8rem;
		margin: -40% 0 0 0;
		line-height: 3;
	}
	.f_a_t__welcomeSectionTextH2 > h2 {
		font-size: 0.7rem;
	}
	.f_a_t__welcomeSectionTextH2 {
		width: 65vw;
	}
	.f_a_t__welcomeSectionDash {
		display: none;
	}

	/**** QuoteSection text ****/
	.f_a_t__quoteSection > img {
		display: flex;
		align-self: center;
		width: 100%;
		margin-bottom: 5vh;
	}
	.f_a_t__quoteSection > h1 {
		font-size: 1.6rem;
	}

	/**** f_a_t items section ****/
	.f_a_t__itemsSectionItem {
		flex: auto;
		height: 30vh;
		justify-content: center;
		margin: 3rem 0 3rem 0;
	}
	.f_a_t__itemsSectionItem > * {
		margin-bottom: 16px;
	}
	.f_a_t__itemsSectionItem:nth-child(1),
	.f_a_t__itemsSectionItem:nth-child(4) {
		margin-right: 0rem;
	}
	.f_a_t__itemsSectionItem:nth-child(2),
	.f_a_t__itemsSectionItem:nth-child(5) {
		margin-left: 0rem;
	}
	.f_a_t__itemsSectionContainer {
		flex-direction: column;
		height: auto;
	}

	/**** Introduction Section ****/
	.f_a_t__introductionContianer > img {
		max-width: 90%;
		margin: 0 0 -15% 0;
	}
	.f_a_t__introductionDescription {
		padding: 2rem;
	}
	.f_a_t__introductionDescription > h1 {
		line-height: 1.4;
		padding-bottom: 2rem;
	}
}

/*-----Rest of CSS is in FoodAndTraining.css-----*/
/*-----Welcome Section-----*/
.training__welcomeSectionText {
	background-image: url(/static/media/WelcomeImg.81abfce8.png);
}

/*-----Media querrys-----*/
/* Only for quoteSection Heading */
@media (max-width: 1380px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/*-----Rest of CSS is in FoodAndTraining.css-----*/
/*-----Welcome Section-----*/
.food__welcomeSectionText {
	background-image: url(/static/media/WelcomeImg.53775de4.png);
}

/*-----Macros Section-----*/
.food__macros {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 10vh;
}

.food__macros > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
}

.macros__green {
	color: #628989;
}

.food__macros > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 80%;
	text-align: center;
	margin-top: 4vh;
}

/*-----Macros Container Section-----*/
.food__macrosContainer {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	margin-top: 10vh;
}

.macrosContainer__item {
	display: flex;
	flex: 0.3 1;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 40vh;
	height: auto;
	border: 1px solid #f7f7f7;
	border-radius: 10px;
}

.macrosContainer__item > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
	width: 80%;
	margin-top: 4vh;
}

.macrosContainer__item > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	text-align: center;
	width: 80%;
	color: #628989;
	margin-top: 4vh;
	margin-bottom: 4vh;
}

.macrosContainer__itemRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 80%;
	border-bottom: 1px solid #f7f7f7;
	border-top: 1px solid #f7f7f7;
	margin-top: 4vh;
}

.macrosContainer__itemRow > img {
	height: 100%;
	width: auto;
	padding: 1rem;
}

/*-----Charts Section-----*/
.food__charts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	background-image: url(/static/media/FoodBG.e422c41b.svg);
	padding: 8vh 0 12vh 0;
	margin-top: 10vh;
}

.food__chartsHeading {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 40%;
	height: auto;
	margin-bottom: 6vh;
}

.chartsHeading__type {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.chartsHeading__typeCircle {
	width: 10px;
	height: 10px;
	background-color: #00001d;
	border-radius: 50%;
}

.chartsHeading__typeCirclePro {
	background-color: #c78567;
}

.chartsHeading__typeCircleFat {
	background-color: #527474;
}

.chartsHeading__typeCircleCarbs {
	background-color: #71a0a0;
}

.chartsHeading__type > h1 {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	color: #00001d;
	padding-left: 1vw;
}

/*-----Charts container section-----*/
.food__chartsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 90%;
	height: auto;
}

/*-----Micros Secion-----*/
.food__micros {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 10vh;
	margin-bottom: 1vh;
}

.food__micros > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
}

.food__micros > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 70%;
	text-align: center;
	margin-top: 4vh;
}

/*-----Micros Vitamins & Minerals Secion-----*/
.food__microsVitamins,
.food__microsMinerals {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	width: 90%;
	height: auto;
	margin-top: 8vh;
	position: relative;
}

.microsVitamins__info,
.microsMinerals__info {
	display: flex;
	flex: 0.6 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 2rem;
	border: 1px solid #f7f7f7;
	position: relative;
}

.microsVitamins__info > h1,
.microsMinerals__info > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	margin-bottom: 4vh;
}

.microsVitamins__info > ul > li,
.microsMinerals__info > ul > li {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 0.8rem;
	line-height: 1.6;
	color: #7e7b7b;
	margin-bottom: 0.4vh;
}

.microsVitamins__infoContainer {
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-row-gap: 2vh;
	row-gap: 2vh;
	justify-content: space-between;
	align-items: flex-end;
	width: 90%;
	margin-top: 4vh;
}

.microsVitamins__infoContainer > img,
.microsVitamins__infoContainer > img {
	justify-self: flex-end;
}

.microsVitamins__img,
.microsMinerals__img {
	display: flex;
	flex: 0.3 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: auto;
}

/*-----Micros Minerals Secion-----*/
.food__microsMinerals {
	margin-top: 10vh;
}

.microsMinerals__infoContainer {
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-row-gap: 2vh;
	row-gap: 2vh;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin: 4vh 0 4vh 0;
}

.microsMinerals__infoContainer > img {
	transform: scale(0.9);
}

.microsMinerals__info > h1:nth-of-type(2) {
	margin-bottom: 0;
}

/*-----TDEE calculator-----*/
.food__tdee {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 9vh;
	margin-bottom: 1vh;
}

.food__tdee > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #c78567;
	max-width: 90%;
}

.food__tdee > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 70%;
	text-align: center;
	margin-top: 4vh;
	margin-bottom: 8vh;
}

.food__tdeeForm,
.food__tdeeForm > form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: auto;
}

.food__tdeeForm > form > h1 {
	font-size: 1.2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 1px;
	color: #c78567;
	width: 100%;
	text-align: center;
	padding: 1vh 0 1vh 0;
	background-image: url(/static/media/FoodBG.e422c41b.svg);
}

.tdeeForm__info,
.tdeeForm__sex {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 80%;
	padding: 4vh 0 4vh 0;
}

.tdeeForm__info > input {
	display: flex;
	flex: 0.2 1;
	height: 100%;
	padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #eeeeee;
	resize: none;
}

.tdeeForm__option {
	display: flex;
	flex: 0.2 1;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	border: 1px solid #eeeeee;
	position: relative;
}

.tdeeForm__option > label {
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	width: 100%;
	padding: 1rem 1.5rem 1rem 2.5rem;
}

/* TDEE FORM OPTION CHECKBOX STYLING */
.tdeeForm__option > input[type="checkbox"]:not(:checked),
.tdeeForm__option > input[type="checkbox"]:checked {
	position: absolute;
	right: 1rem;
	opacity: 0.01;
}

/* General label styling */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label,
.tdeeForm__option > input[type="checkbox"]:checked + label {
	position: relative;
	cursor: pointer;
}

/* General label styling when checked*/
.tdeeForm__option > input[type="checkbox"]:checked + label {
	background-color: #f2f2f2;
}

/* General checkmark styling when NOT checked */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label:before,
.tdeeForm__option > input[type="checkbox"]:checked + label:before {
	content: "";
	position: absolute;
	right: 1rem;
	align-self: center;
	width: 1rem;
	height: 1rem;
	border: none;
	transition: all 0.275s;
}

/* General checkmark styling when ISchecked */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label:after,
.tdeeForm__option > input[type="checkbox"]:checked + label:after {
	content: " \2713";
	font-size: 1rem;
	right: 1rem;
	align-self: center;
	position: absolute;
	color: #628989;
	transition: all 0.2s;
}

/* Adding some animation to it */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label:after {
	opacity: 0;
	transform: scale(0) rotate(45deg);
}

.tdeeForm__option > input[type="checkbox"]:checked + label:after {
	opacity: 1;
	transform: scale(1) rotate(0);
}

.tdeeForm__activity {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-content: center;
	padding: 4vh 0 4vh 0;
	width: 90%;
}

/*-----Tdee results-----*/
.food__tdeeResult {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 80%;
	margin: 8vh 0 8vh 0;
}

.tdeeResult__item {
	display: flex;
	flex: 0.25 1;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 25vh;
	transition: .2s all;
}

.tdeeResult__item > h1 {
	font-size: 0.9rem;
	font-family: "Raleway", sans-serif;
	font-weight: bold;
	letter-spacing: 2px;
	line-height: 1.4;
	color: #01011d;
	text-align: center;
}

.tdeeResult__item > h1:last-of-type {
	font-size: 0.8rem;
	color: #8c8989;
}

.tdeeResult__item > h2 {
	font-size: 7rem;
	font-family: "Playfair Display", serif;
	letter-spacing: 2px;
	color: #c78567;
	cursor: pointer;

	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}



/*-----Calculating macros, macros result section-----*/
.food__macrosResult {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	background-image: url(/static/media/FoodBG.e422c41b.svg);
	margin-top: 8vh;
	padding-bottom: 4vh;
}

.food__macrosResult > h1 {
	width: 80%;
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
	margin: 4vh 0 8vh 0;
}

.macrosResult__container {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 88%;
	height: 25vh;
	background-color: #ffffff;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
	margin: 2vh 0 2vh 0;
}

.macrosResult__name {
	display: flex;
	flex: 0.25 1;
	justify-content: center;
	align-items: center;
	height: 100%;
	background-color: #c78567;
}

.macrosResult__name > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #ffffff;
	text-align: center;
}

.macrosResult__info {
	display: flex;
	flex: 0.6 1;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 100%;
}

.macrosResult__infoHeading {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
}

.macrosResult__infoHeading > h1 {
	font-size: 1.6rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
}

.macrosResult__small {
	font-size: 50%;
}

.macrosResult__info > ul {
	width: 100%;
}

.macrosResult__info > ul > li {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 0.8rem;
	line-height: 1.6;
	color: #7e7b7b;
	margin-bottom: 0.4vh;
}

.macrosResult__result {
	display: flex;
	flex: 0.1 1;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
}

.macrosResult__result > h1 {
	font-size: 3rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #c78567;
	text-align: center;
}

/*-----Macros section color edit-----*/
.prot > .macrosResult__name {
	background-color: #527474;
}

.prot > .macrosResult__result > h1 {
	color: #527474;
}

.carbs > .macrosResult__name {
	background-color: #71a0a0;
}

.carbs > .macrosResult__result > h1,
.food__waterEdit {
	color: #71a0a0;
}

/*-----Water section-----*/
.food__water {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 9vh;
	margin-bottom: 1vh;
}

.food__water > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
}

.food__water > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 80%;
	text-align: center;
	margin-top: 4vh;
}

.food__waterContainer {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	width: 90%;
	margin: 8vh 0 2vh 0;
}

.waterContainer__info {
	display: flex;
	flex: 0.6 1;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: auto;
}

.waterContainer__info > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
}

.waterContainer__info > ul {
	margin-left: 1rem;
	margin-top: 4vh;
}

.waterContainer__info > ul > li {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 0.8rem;
	line-height: 1.6;
	color: #7e7b7b;
	margin-bottom: 0.4vh;
}

.waterContainerInfo__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	height: auto;
	margin-top: 6vh;
	margin-left: 1rem;
}

.waterContainerInfo__item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 5vw;
	width: auto;
}

.waterContainerInfo__item > img {
	margin-left: 2vw;
}

.waterContainerInfo__item > h1 {
	margin-top: 2vh;
	font-family: "Raleway", sans-serif;
	letter-spacing: 1px;
	font-size: 0.9rem;
	line-height: 1.2;
	color: #01011d;
	font-weight: bold;
	text-align: center;
}

.waterContainer__img {
	display: flex;
	flex: 0.3 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;
}

/*-----Water text-----*/
.food__water > h2 {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #00001d;
	width: 70%;
	text-align: center;
	margin-bottom: 8vh;
}

/*-----Ending Quote-----*/
.food__end {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 6vh 0 6vh 0;
	width: 100%;
	background-image: url(/static/media/FoodBG.e422c41b.svg);
}

.food__end > h1 {
	font-size: 2rem;
	font-family: "Raleway", serif;
	line-height: 1.4;
	letter-spacing: 4px;
	color: #00001d;
	text-align: center;
	width: 80%;
	margin-bottom: 2vh;
}

.food__end > h2 {
	font-size: 1.2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #7e7b7b;
	text-align: right;
	width: 85%;
}

.foodEnd__edit {
	color: #c78567;
	font-weight: bold;
}

/*-----Media querrys-----*/
/* Only for quoteSection Heading */
@media (max-width: 1380px) {
	/**** Macros viramins grid ****/
	.microsVitamins__infoContainer,
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(5, auto);
	}

	/**** Macros result section ****/
	.macrosResult__infoHeading {
		width: 90%;
	}
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Charts container section ****/
	.food__chartsContainer > img {
		width: 18%;
		height: auto;
	}

	/**** Macros viramins and Minerals ****/
	.microsVitamins__img {
		position: absolute;
		right: -9%;
		top: -12%;
		transform: scale(0.5);
	}
	.microsVitamins__info,
	.microsMinerals__info {
		flex: 1 1;
	}
	.microsVitamins__info > ul,
	.microsMinerals__info > ul,
	.microsMinerals__infoContainer {
		width: 70%;
	}
	.microsVitamins__infoContainer,
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(7, auto);
	}
	.microsMinerals__info {
		align-items: flex-end;
	}
	.microsMinerals__info > h1 {
		width: 70%;
		text-align: left;
	}
	.microsMinerals__img {
		position: absolute;
		transform: scale(0.5);
		left: -12%;
		top: -12%;
	}

	/**** TDEE calculator ****/
	.tdeeResult__item > h2 {
		font-size: 5.5rem;
	}

	/**** Macros result section ****/
	.macrosResult__info > ul > li {
		font-size: 0.7rem;
	}
	.macrosResult__infoHeading > h1 {
		font-size: 1.4rem;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Macros Section ****/
	.food__macrosContainer {
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		width: 90%;
	}
	.macrosContainer__item {
		flex: auto;
		width: 40vw;
		margin-bottom: 5vh;
	}
	.macrosContainer__item:nth-child(2) {
		position: absolute;
		right: 0;
	}

	/**** Charts container section ****/
	.food__chartsHeading {
		width: 80%;
	}
	.food__chartsContainer > img {
		width: 30%;
		height: auto;
		margin-bottom: 8vh;
	}
	.food__chartsContainer > img:nth-child(2),
	.food__chartsContainer > img:nth-child(5) {
		margin-bottom: 0;
	}
	.food__chartsContainer {
		flex-wrap: wrap;
	}

	/**** Macros minerlas and viramins ****/
	.microsVitamins__img {
		transform: scale(0.4);
		right: -15%;
		top: -3%;
	}
	.microsMinerals__img {
		transform: scale(0.3);
		top: -20%;
		left: -18%;
	}
	.microsMinerals__info > h1:nth-of-type(2) {
		width: 100%;
	}
	.microsMinerals__infoContainer {
		width: 100%;
	}

	/**** TDEE calculator ****/
	.tdeeForm__activity {
		flex-wrap: wrap;
		padding: 4vh 0 0 0;
	}
	.tdeeForm__activity > .tdeeForm__option > label {
		width: 20vw;
	}
	.tdeeForm__activity > .tdeeForm__option {
		margin-bottom: 4vh;
	}
	.tdeeForm__info > input {
		width: 20%;
	}
	.tdeeResult__item > h2 {
		font-size: 5rem;
	}

	/**** Macros result section ****/
	.macrosResult__container {
		flex-direction: column;
		height: auto;
	}
	.macrosResult__name {
		width: 100%;
	}
	.macrosResult__name > h1 {
		margin: 2vh 0 2vh 0;
	}
	.macrosResult__info {
		margin: 4vh 0;
		max-width: 90%;
	}
	.macrosResult__info > ul > li {
		font-size: 0.8rem;
	}
	.macrosResult__infoHeading {
		justify-content: space-evenly;
		margin-bottom: 4vh;
	}
	.macrosResult__infoHeading > h1 {
		font-size: 1.6rem;
	}
	.macrosResult__result {
		margin-bottom: 4vh;
	}

	/**** Water section ****/
	.food__waterContainer,
	.waterContainer__info {
		flex-direction: column;
		align-items: center;
	}

	.waterContainer__img {
		display: none;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Macros Section ****/
	.macrosContainer__item:nth-child(2) {
		position: relative;
		align-self: flex-end;
	}

	/**** Charts container section ****/
	.food__chartsContainer > img {
		margin-bottom: 4vh;
	}

	/**** Macros minerals amd viramins ****/
	.microsVitamins__img,
	.microsMinerals__img {
		display: none;
	}
	.microsVitamins__info,
	.microsMinerals__info {
		align-items: center;
	}
	.microsMinerals__info > h1,
	.microsMinerals__info > h1:nth-of-type(2) {
		width: auto;
	}
	.microsVitamins__info > ul,
	.microsMinerals__info > ul {
		width: 100%;
	}
	.microsVitamins__infoContainer {
		grid-template-columns: repeat(6, auto);
	}
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(4, auto);
	}

	/**** TDEE calculator ****/
	.food__tdee > h1 {
		font-size: 3.5rem;
	}
	.tdeeForm__sex > .tdeeForm__option > label {
		width: 20vw;
	}
	.tdeeResult__item {
		height: 20vh;
	}
	.tdeeResult__item > h1 {
		font-size: 0.7rem;
	}
	.tdeeResult__item > h1:nth-of-type(2) {
		font-size: 0.6rem;
	}
	.tdeeResult__item > h2 {
		font-size: 3rem;
	}

	/**** Macros result section ****/
	.food__macrosResult > h1 {
		font-size: 3.5rem;
	}
	.macrosResult__infoHeading {
		flex-direction: column;
	}
	.macrosResult__infoHeading > h1:first-of-type {
		margin-bottom: 1vh;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Macros Section ****/
	.macrosContainer__item,
	.macrosContainer__item:nth-child(2) {
		align-self: center;
		width: 90%;
	}
	.food__macros > h1 {
		font-size: 2.8rem;
	}

	/**** Charts container section ****/
	.food__chartsHeading {
		width: 90%;
	}
	.chartsHeading__type > h1 {
		font-size: 0.8rem;
	}
	.food__chartsContainer {
		flex-direction: column;
	}
	.food__chartsContainer > img {
		width: 60%;
		height: auto;
		margin-bottom: 8vh;
	}
	.food__chartsContainer > img:last-child {
		margin-bottom: 0;
	}
	.food__chartsContainer {
		flex-wrap: nowrap;
	}

	/**** Macros viramins ****/
	.food__micros > h1 {
		font-size: 2.8rem;
	}
	.food__micros > p {
		font-size: 0.8rem;
		width: 80%;
	}
	.microsVitamins__infoContainer,
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(3, auto);
		margin-left: -5%;
	}
	.microsVitamins__infoContainer > img,
	.microsMinerals__infoContainer > img {
		width: 80%;
		height: auto;
	}
	.microsVitamins__infoContainer > img:nth-child(2),
	.microsMinerals__infoContainer:first-of-type > img:nth-child(2) {
		width: 65%;
		height: auto;
	}
	.microsVitamins__info > ul > li,
	.microsMinerals__info > ul > li {
		margin-bottom: 1vh;
	}

	/**** TDEE calculator ****/
	.food__tdee > h1 {
		font-size: 2.4rem;
		text-align: center;
	}
	.tdeeForm__info,
	.tdeeForm__sex {
		flex-direction: column;
		width: 70%;
		padding: 4vh 0 0 0;
	}
	.tdeeForm__info > input,
	.tdeeForm__option {
		width: 100%;
		margin-bottom: 4vh;
	}
	.tdeeForm__activity {
		flex-wrap: nowrap;
		flex-direction: column;
		width: 70%;
	}
	.tdeeForm__option > label {
		width: 100% !important;
	}
	.food__tdeeResult {
		flex-direction: column;
		margin: 8vh 0 0 0;
	}
	.tdeeResult__item {
		margin-bottom: 6vh;
	}
	.tdeeResult__item > h2 {
		margin: 1vh 0 1vh 0;
	}

	/**** Macros result section ****/
	.food__macrosResult > h1 {
		font-size: 2.8rem;
	}
	.macrosResult__infoHeading > h1 {
		font-size: 1.2rem;
	}
	.macrosResult__info > ul > li {
		font-size: 0.7rem;
		margin: 1vh 0;
	}

	/**** Water section ****/
	.food__water > h1 {
		font-size: 2.8rem;
	}
	.waterContainer__info > h1 {
		font-size: 1.6rem;
	}
	.waterContainer__info > ul > li {
		margin-bottom: 1vh;
	}
	.waterContainerInfo__container {
		justify-content: space-evenly;
	}
	.waterContainerInfo__item {
		align-items: center;
		flex: 0.3 1;
	}
	.waterContainerInfo__item > img {
		width: 80%;
		height: auto;
	}
	.waterContainerInfo__item > h1 {
		font-size: 0.7rem;
	}

	/**** Food ending ****/
	.food__end > h1 {
		font-size: 1.6rem;
	}
	.food__end > h2 {
		font-size: 1rem;
	}
}

/*-----Resizing component + img -----*/
.slideshow__container {
	width: 100%;
	height: auto;
	max-height: 75vh;
	position: relative;
	overflow: hidden;
}

.slideshow__itemImg {
	width: 100%;
	height: auto;
}

/*-----Changing Incicators + hover, transition...-----*/
.indicators {
	position: absolute;
	bottom: 10%;
	width: 100%;
}

.each-indicator {
	height: 10px;
	width: 10px;
	margin-left: 1vw;
	margin-right: 1vw;
	background-color: white;
	cursor: pointer;
	border-radius: 50%;
}

.each-indicator:not(:hover) {
	transform: scale(1);
	transition: 0.25s;
}

.each-indicator:hover {
	background-color: #a3bdbd;
	transform: scale(1.15);
	transition: 0.25s;
}

.active {
	background-color: #5a8181;
}

/*-----Media querrys-----*/
/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.each-indicator {
		height: 6px;
		width: 6px;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.each-indicator {
		height: 8px;
		width: 8px;
	}
	.slideshow__itemImg {
		height: 75vh;
		object-fit: cover;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/*-----Global Componentns-----*/
.swiperItem__container {
	display: flex;
	flex: 0.33 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/*-----Images sizing, positioning-----*/
.swiperItem__containerImg {
	position: relative;
	padding: 5%;
}

.swiperItem__containerImg > img {
	width: 100%;
	max-width: 334px;
	max-height: 310px;
	position: relative;
	z-index: 10;
}

/*-----h1,h2 positioning + sizing-----*/
.swiperItem__containerText {
	width: 65%;
	cursor: pointer;
}
/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

.swiperItem__containerText > h4 {
	font-weight: bold;
	font-family: "Raleway", serif;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #5a8181;

	padding-top: 0.8rem;
}

.swiperItem__containerText > h1 {
	font-weight: medium;
	font-family: "Playfair Display", serif;
	font-size: 2rem;
	line-height: 1.3;
	color: #00001d;
	padding-top: 1.4rem;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	.swiperItem__containerText > h1 {
		font-size: 1.7rem;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.swiperItem__backImg {
		display: none;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.swiperItem__backImg {
		display: flex;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.home__continerFluid {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.home__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 88%;
}

/*-----Slide show text-----*/
.home__slideShow {
	position: relative;
}

.home__slideShowText {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 75%;
	height: 40vh;
}

.home__slideShowText > h1 {
	font-size: 6em;
	font-weight: regular;
	font-family: "Playfair Display", serif;
	color: white;
	margin-top: -10%;
}

.home__slideShowText > h3 {
	margin-top: 2%;
	font-size: 1em;
	letter-spacing: 3px;
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	color: white;
}

.home__slideShowTextEdit > img {
	max-width: 1rem;
	max-height: 2rem;
	margin-left: 2.5rem;
	margin-right: 1rem;
}

/*-----Newest blog posts-----*/
.home__newest {
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	margin-top: 10%;
	margin-bottom: 10%;
}

.home__newestHeading {
	width: 100%;
	position: relative;
}

.home__newestHeading > h1 {
	width: 100%;
	text-align: left;
	font-size: 1.4rem;
	font-family: "Raleway", sans-serif;
	font-weight: bolder;
	letter-spacing: 2px;
	padding-bottom: 1.5rem;
	color: #00001d;
	border-bottom: 2px solid #eeeeee;
}

.newestHeadingBorder {
	padding-bottom: 1.5rem;
	border-bottom: 2px solid #628989;
}

.newestHeadingSwitch {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
}

.newestHeadingSwitch > img {
	padding: 0 1.5rem 1.5rem 1.5rem;
	transform: scale(1.2);
	cursor: pointer;
	transition: all 0.3s ease;
}

.newestHeadingSwitch > img:active {
	transform: scale(.9);
}

.home__newestPosts {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 4%;
}

/*-----FindMore section-----*/
.home__FindMore {
	display: flex;
	flex: 1 1;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 5%;
	margin-bottom: 5%;
	min-height: 75vh;
	background-image: url(/static/media/FindMoreBG.4b2961d2.svg);
}

.home__FindMoreContainer {
	display: flex;
	flex: 1 1;
	flex-direction: row;
	justify-content: space-between;
	width: 84%;
	height: 100%;
}

.home__FindMoreContainer > * {
	display: flex;
	flex-direction: column;
	flex: 0.3 1;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
	background-color: white;
}

.home__FindMoreContainer > * > img {
	display: flex;
	align-self: flex-end;
	width: 85%;
}

.home__FindMoreContainer > * > a {
	display: flex;
	align-self: flex-end;
	margin: 2.5rem 2rem 1.5rem 0;
	text-decoration: none;
	transition: all 0.3s ease;
}

.home__FindMoreContainer > * > a:active {
	transform: scale(.9);
}

.home__FindMoreContainer > * > a > h4 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #5a8181;
}

.home__FindMoreContainer > * > h1 {
	font-size: 3rem;
	font-weight: medium;
	font-family: "Playfair Display", serif;
	padding: 2.5rem 0 4rem 2rem;
	color: #00001d;
}

.home__FindMoreBlog {
	align-self: flex-start;
	margin-top: -1rem;
}

.home__FindMoreTraining {
	align-self: center;
}

.home__FindMoreFood {
	align-self: flex-end;
	margin-bottom: -1rem;
}

/*-----youCAN section-----*/
.home__youCan {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 70%;
	margin-top: 5%;
	margin-bottom: 15%;
}

.home__youCan > h1 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 2rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #00001d;
	margin-bottom: 15%;
}

.home__youCanImgs {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.home__youCanImgs > img {
	transform: scale(1.5);
}

/*-----Contact us section-----*/
.home__contact {
	margin-top: 10%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 75vh;
	background-image: url(/static/media/FormBG.19dcfe13.svg);
	background-size: cover;
	background-repeat: no-repeat;
}

.home__contactContent {
	display: flex;
	flex-direction: row;
	width: 75%;
	height: 100%;
}

.home__contactForm {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: -5%;
	background-color: #ffffff;
	box-shadow: 0 4px 7px rgba(0, 0, 0, 0.15);
	border: 1px solid #eeeeee;
	height: 100%;
	width: 50rem;
	border: 10%;
}

.home__contactForm > form {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 70%;
	height: 80%;
}

.home__contactForm > form > * {
	padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #eeeeee;
	resize: none;
}

.home__contactForm > form > *:first-child {
	border: none;
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.home__contactForm > form > *:last-child {
	width: 50%;
	align-self: center;
	font-weight: 600;
	background-color: white;
	color: #638a8a;
	padding: 3%;
	border: 1.5px solid #638a8a;
	transition: all 0.3s ease;
}

.home__contactForm > form > *:last-child:active {
	transform: scale(.9);
}

::-webkit-input-placeholder {
	color: #dedede;
}

:-ms-input-placeholder {
	color: #dedede;
}

::-ms-input-placeholder {
	color: #dedede;
}

::placeholder {
	color: #dedede;
}

.contactFormEdit {
	color: #c78567;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Text in SlideShow ****/
	.home__slideShowText {
		justify-content: center;
	}
	.home__slideShowText > h1 {
		font-size: 5em;
	}

	/**** FindMore section ****/
	.home__FindMoreContainer > * > h1 {
		font-size: 2.5rem;
		padding: 2.5rem 0 2rem 2rem;
	}

	/**** youCAN section ****/
	.home__youCanImgs > img {
		transform: scale(1);
	}

	/**** Contact Us section ****/
	.home__contactContent {
		flex-direction: column;
		align-items: center;
	}
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Text in SlideShow ****/
	.home__slideShowText > h1 {
		font-size: 4em;
	}
	.home__slideShowText > h3 {
		font-size: 0.7em;
		letter-spacing: 2px;
	}

	/**** FindMore section ****/
	.home__FindMoreContainer {
		position: relative;
		flex-direction: column;
	}
	.home__FindMoreContainer > * > h1 {
		font-size: 3rem;
		padding: 2.5rem 0 4rem 2rem;
	}
	.home__FindMoreFood {
		margin-top: 20%;
		align-self: flex-start;
	}
	.home__FindMoreTraining {
		position: absolute;
		top: 33%;
		align-self: flex-end;
	}

	/**** youCAN section ****/
	.home__youCanImgs > img {
		transform: scale(0.75);
	}
	.home__youCan > h1 {
		font-size: 1.8rem;
	}

	/**** Contact Us section ****/
	.home__contactForm {
		width: 100%;
	}
	.home__contactForm > form > *:last-child {
		padding: 5%;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Text in SlideShow ****/
	.home__slideShowText {
		justify-content: flex-end;
	}
	.home__slideShowText > h1 {
		font-size: 3em;
	}
	.home__slideShowText > h3 {
		margin-top: 6%;
		font-size: 0.6em;
	}
	.home__slideShowTextEdit > img {
		max-width: 0.7rem;
	}

	/**** Newest section ****/
	.home__newest {
		margin-top: 18%;
	}
	.newestHeadingSwitch {
		display: none;
	}
	.home__newestPosts {
		margin-top: 8%;
	}

	/**** FindMore section ****/
	.home__FindMoreFood {
		margin-top: 0%;
	}
	.home__FindMoreTraining {
		position: relative;
		margin-bottom: 5%;
	}
	.home__FindMoreBlog {
		margin-bottom: 5%;
	}
	.home__FindMore {
		margin-top: 10%;
	}

	/**** youCAN section ****/
	.home__youCanImgs > img {
		width: 30%;
	}
	.home__youCan > h1 {
		margin-top: 15%;
		margin-bottom: 5%;
		font-size: 1.6rem;
	}

	/**** Contact Us section ****/
	.home__contactContent {
		width: 90%;
	}

	/**** Happy Footer ****/
	.home__footerLeft {
		display: none;
	}
	.home__footerRight {
		flex: 1 1;
		flex-direction: row;
		justify-content: space-around;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Text in SlideShow ****/
	.home__slideShowText > h1 {
		font-size: 2.2rem;
		text-align: center;
	}
	.home__slideShowText > h3 {
		padding-top: 10%;
		text-align: center;
		line-height: 1.5;
		font-size: 0.5rem;
	}
	.home__slideShowTextEdit {
		display: none;
	}

	/**** Newest section ****/
	.home__newestPosts {
		margin-top: 15%;
	}

	/**** FindMore section ****/
	.home__FindMoreFood {
		align-self: center;
	}
	.home__FindMoreTraining {
		position: relative;
		margin-bottom: 10%;
		align-self: center;
	}
	.home__FindMoreBlog {
		margin-bottom: 10%;
		align-self: center;
	}
	.home__FindMoreContainer > * > h1 {
		font-size: 2.2rem;
	}
	.home__FindMore {
		margin-top: 15%;
	}

	/**** youCAN section ****/
	.home__youCan {
		margin-bottom: 60%;
	}
	.home__youCanImgs > img {
		width: auto;
		transform: scale(0.75);
	}
	.home__youCan > h1 {
		margin-top: 40%;
		margin-bottom: 20%;
		font-size: 2.5rem;
	}
	.home__youCanImgs {
		flex-direction: column;
		height: 70vh;
	}

	/**** Contact Us section ****/
	.home__contactForm > form > *:first-child {
		font-size: 1.5rem;
		padding: 0;
		margin-bottom: 1.5rem;
	}
	.home__contactForm > form > * {
		margin-top: 10px;
		font-size: 0.8rem;
	}
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.loader__container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 90vh;
	overflow: hidden;
}

.loader__container > h3 {
	color: black;
	font-weight: regular;
	font-family: "Playfair Display", serif;
	font-size: 3rem;
	padding-left: 0.2rem;
	letter-spacing: 0.2rem;
	text-align: center;
	-webkit-animation: spacing 3s linear infinite;
	        animation: spacing 3s linear infinite;
}

@-webkit-keyframes spacing {
	15% {
		letter-spacing: 0.2rem;
		padding-left: 0.2rem;
	}

	50% {
		letter-spacing: 2rem;
		padding-left: 2rem;
	}

	85% {
		letter-spacing: 0.2rem;
		padding-left: 0.2rem;
	}
}

@keyframes spacing {
	15% {
		letter-spacing: 0.2rem;
		padding-left: 0.2rem;
	}

	50% {
		letter-spacing: 2rem;
		padding-left: 2rem;
	}

	85% {
		letter-spacing: 0.2rem;
		padding-left: 0.2rem;
	}
}

/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url(/static/media/Raleway-VariableFont_wght.942638e6.ttf);
}
@font-face {
	font-family: Playfair Display;
	src: url(/static/media/PlayfairDisplay-VariableFont_wght.ad3d45b3.ttf);
}

/*-----Global Componentns-----*/
.pageNotFound__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 152px);
	width: 100%;
}

.pageNotFound__container > h1 {
	font-size: 10rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	color: #00001d;
	margin-top: -20vh;
	line-height: 1.4;
}

.pageNotFound__container > h2 {
	font-size: 4rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	color: #00001d;
}

.pageNotFound__border {
	border-bottom: 5px solid #628989;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.pageNotFound__container > h2 {
		font-size: 3rem;
	}
	.pageNotFound__border {
		border-bottom: 2px solid #628989;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.pageNotFound__container > h1 {
		font-size: 8rem;
	}
	.pageNotFound__container > h2 {
		font-size: 1.5rem;
	}
}

