/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url("../../assets/fonts/Raleway-VariableFont_wght.ttf");
}
@font-face {
	font-family: Playfair Display;
	src: url("../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

/*-----Global Componentns-----*/
.blog__container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}

/*-----Upper container-----*/
.blog__upperCont {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: flex-start;
	align-self: flex-end;
	justify-content: space-between;
	height: calc(100vh - 152px);
	width: 94%;
}

.blog__upperImg {
	display: flex;
	flex: 0.5;
	align-items: flex-start;
	justify-content: flex-end;
	height: 100%;
	background-color: #5a8181;
}

.blog__upperImg > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

/*----- Description styling -----*/
.blog__description {
	display: flex;
	flex: 0.4;
	flex-direction: column;
	align-items: flex-start;
}

.blog__description > h3 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #5a8181;
	margin-top: 5vh;
}

.blog__description > h1 {
	font-size: 4rem;
	font-weight: medium;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	text-align: left;
	color: #00001d;
	margin: 6vh 0 8vh 0;
}

.blog__descriptionAuthorDate {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.blog__descriptionAuthorDate > h5 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #c9c9c9;
}

.blog__descriptionAuthorDate > h4 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	letter-spacing: 2px;
	color: #5a8181;
}

.blog__line {
	background-color: #5a8181;
	height: 2px;
	width: 2rem;
	margin: 0 2rem 0 2rem;
}

/*----- Lower container -----*/
.blog__lowerCont {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	height: auto;
}

/*-----Posts list section-----*/
.blog__postsListCont {
	display: flex;
	flex: 0.3;
	flex-direction: column;
	align-items: center;
	background-image: url("../../assets/BG/BlogBG.svg");
}

.blog__postsList {
	width: 70%;
	margin-top: 5vh;
}

.blog__postsList > h3 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 1rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #5a8181;
}

.blog__postsListBorder {
	padding-bottom: 1.6rem;
	border-bottom: 2px solid #a3a3a3;
}

.blog__postsListItem {
	margin-top: 1.2rem;
	padding-left: 0.8rem;
	padding-right: 1.2rem;
	cursor: pointer;
	transition: all 0.3s ease;
}

.blog__postsListItem:active {
	transform: scale(.9);
}

.blog__postsListItem > h5 {
	font-weight: bold;
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: #5a8181;
	margin-bottom: 0.6rem;
}

.blog__postsListItem > h4 {
	font-size: 1.2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 1px;
	text-align: left;
	color: #00001d;
}

/*-----Post text-----*/
.blog__postText {
	display: flex;
	flex: 0.6;
	flex-direction: column;
	align-items: flex-start;
	margin: 10vh 0 20vh 10vh;
}

.blog__postText > p {
	font-size: 1.2rem;
	font-weight: bolder;
	font-family: "Playfair Display", serif;
	line-height: 1.6;
	letter-spacing: 2px;
	text-align: left;
	color: #00001d;
	margin-bottom: 1.6rem;
}

.blog__postText > p:first-child:first-letter {
	float: left;
	font-size: 500%;
	line-height: 0;
	padding: 0 10px 30px 0;
	margin-top: -15px;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Upper container ****/
	.blog__description > h1 {
		font-size: 3rem;
		margin: 3vh 0 5vh 0;
	}

	/**** lower container ****/
	.blog__postsListItem > h4,
	.blog__postText > p {
		font-size: 1rem;
	}
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Upper container ****/
	.blog__description > h1 {
		font-size: 2.5rem;
		margin: 2vh 0 3vh 0;
	}
	.blog__description h4,
	.blog__description h3 {
		font-size: 0.8rem;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Upper container ****/
	.blog__upperCont {
		flex-direction: column-reverse;
		align-self: center;
		width: 88%;
		height: auto;
	}
	.blog__upperImg {
		width: 100%;
	}
	.blog__description > h1 {
		margin: 2vh 0 4vh 0;
	}
	.blog__descriptionAuthorDate {
		justify-content: flex-end;
	}

	/**** Lower container ****/
	.blog__lowerCont {
		flex-direction: column-reverse;
	}
	.blog__postText {
		margin: 10vh 0 5vh 0;
		width: 88%;
		align-self: center;
	}
	.blog__postsList {
		width: 80%;
		padding-bottom: 10vh;
	}
	.blog__postsList > h3 {
		line-height: 1.5;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Upper container ****/
	.blog__description > h1 {
		font-size: 2rem;
	}
	.blog__postText {
		width: 82%;
	}
}
