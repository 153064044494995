/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url("../../assets/fonts/Raleway-VariableFont_wght.ttf");
}
@font-face {
	font-family: Playfair Display;
	src: url("../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

/*-----Global Componentns-----*/
.loader__container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 90vh;
	overflow: hidden;
}

.loader__container > h3 {
	color: black;
	font-weight: regular;
	font-family: "Playfair Display", serif;
	font-size: 3rem;
	padding-left: 0.2rem;
	letter-spacing: 0.2rem;
	text-align: center;
	animation: spacing 3s linear infinite;
}

@keyframes spacing {
	15% {
		letter-spacing: 0.2rem;
		padding-left: 0.2rem;
	}

	50% {
		letter-spacing: 2rem;
		padding-left: 2rem;
	}

	85% {
		letter-spacing: 0.2rem;
		padding-left: 0.2rem;
	}
}
