/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url("../../assets/fonts/Raleway-VariableFont_wght.ttf");
}
@font-face {
	font-family: Playfair Display;
	src: url("../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

/*-----Global Componentns-----*/
.post__continer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	width: 100%;
	align-self: center;
	justify-self: center;
}

/*-----Direct Styling-----*/
.post__continer > h1 {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "Raleway", serif;
	letter-spacing: 2px;
	text-align: center;
	color: #00001d;
	line-height: 1.6;
	margin-bottom: 2vh;
}

.post__continer > h3 {
	font-size: 1rem;
	font-weight: bolder;
	font-family: "Raleway", serif;
	letter-spacing: 2px;
	text-align: center;
	color: #00001d;
	margin-bottom: 2vh;
	line-height: 1.6;
}

.post__continer > h4 {
	font-size: 1rem;
	font-weight: bolder;
	font-family: "Playfair Display", serif;
	letter-spacing: 2px;
	text-align: center;
	color: #5a8181;
	border-bottom: 1px solid #5a8181;
	padding-bottom: 1rem;
	width: 100%;
	margin-bottom: 2vh;
}

.post__continer > p {
	font-size: 0.8rem;
	font-family: "Raleway", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	text-align: center;
	color: #00001d;
	margin-bottom: 2vh;
}

.post__continer > img {
	width: 100%;
	max-width: 334px;
	max-height: 310px;
}
