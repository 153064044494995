/*-----Rest of CSS is in FoodAndTraining.css-----*/
/*-----Welcome Section-----*/
.food__welcomeSectionText {
	background-image: url("../../assets/food/WelcomeImg.png");
}

/*-----Macros Section-----*/
.food__macros {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 10vh;
}

.food__macros > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
}

.macros__green {
	color: #628989;
}

.food__macros > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 80%;
	text-align: center;
	margin-top: 4vh;
}

/*-----Macros Container Section-----*/
.food__macrosContainer {
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
	margin-top: 10vh;
}

.macrosContainer__item {
	display: flex;
	flex: 0.3;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: 40vh;
	height: auto;
	border: 1px solid #f7f7f7;
	border-radius: 10px;
}

.macrosContainer__item > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
	width: 80%;
	margin-top: 4vh;
}

.macrosContainer__item > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	text-align: center;
	width: 80%;
	color: #628989;
	margin-top: 4vh;
	margin-bottom: 4vh;
}

.macrosContainer__itemRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 80%;
	border-bottom: 1px solid #f7f7f7;
	border-top: 1px solid #f7f7f7;
	margin-top: 4vh;
}

.macrosContainer__itemRow > img {
	height: 100%;
	width: auto;
	padding: 1rem;
}

/*-----Charts Section-----*/
.food__charts {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: auto;
	background-image: url("../../assets/BG/FoodBG.svg");
	padding: 8vh 0 12vh 0;
	margin-top: 10vh;
}

.food__chartsHeading {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 40%;
	height: auto;
	margin-bottom: 6vh;
}

.chartsHeading__type {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.chartsHeading__typeCircle {
	width: 10px;
	height: 10px;
	background-color: #00001d;
	border-radius: 50%;
}

.chartsHeading__typeCirclePro {
	background-color: #c78567;
}

.chartsHeading__typeCircleFat {
	background-color: #527474;
}

.chartsHeading__typeCircleCarbs {
	background-color: #71a0a0;
}

.chartsHeading__type > h1 {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	color: #00001d;
	padding-left: 1vw;
}

/*-----Charts container section-----*/
.food__chartsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 90%;
	height: auto;
}

/*-----Micros Secion-----*/
.food__micros {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 10vh;
	margin-bottom: 1vh;
}

.food__micros > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
}

.food__micros > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 70%;
	text-align: center;
	margin-top: 4vh;
}

/*-----Micros Vitamins & Minerals Secion-----*/
.food__microsVitamins,
.food__microsMinerals {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	width: 90%;
	height: auto;
	margin-top: 8vh;
	position: relative;
}

.microsVitamins__info,
.microsMinerals__info {
	display: flex;
	flex: 0.6;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 2rem;
	border: 1px solid #f7f7f7;
	position: relative;
}

.microsVitamins__info > h1,
.microsMinerals__info > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	margin-bottom: 4vh;
}

.microsVitamins__info > ul > li,
.microsMinerals__info > ul > li {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 0.8rem;
	line-height: 1.6;
	color: #7e7b7b;
	margin-bottom: 0.4vh;
}

.microsVitamins__infoContainer {
	display: grid;
	grid-template-columns: repeat(7, auto);
	row-gap: 2vh;
	justify-content: space-between;
	align-items: flex-end;
	width: 90%;
	margin-top: 4vh;
}

.microsVitamins__infoContainer > img,
.microsVitamins__infoContainer > img {
	justify-self: flex-end;
}

.microsVitamins__img,
.microsMinerals__img {
	display: flex;
	flex: 0.3;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: auto;
}

/*-----Micros Minerals Secion-----*/
.food__microsMinerals {
	margin-top: 10vh;
}

.microsMinerals__infoContainer {
	display: grid;
	grid-template-columns: repeat(7, auto);
	row-gap: 2vh;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	margin: 4vh 0 4vh 0;
}

.microsMinerals__infoContainer > img {
	transform: scale(0.9);
}

.microsMinerals__info > h1:nth-of-type(2) {
	margin-bottom: 0;
}

/*-----TDEE calculator-----*/
.food__tdee {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 9vh;
	margin-bottom: 1vh;
}

.food__tdee > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #c78567;
	max-width: 90%;
}

.food__tdee > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 70%;
	text-align: center;
	margin-top: 4vh;
	margin-bottom: 8vh;
}

.food__tdeeForm,
.food__tdeeForm > form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: auto;
}

.food__tdeeForm > form > h1 {
	font-size: 1.2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 1px;
	color: #c78567;
	width: 100%;
	text-align: center;
	padding: 1vh 0 1vh 0;
	background-image: url("../../assets/BG/FoodBG.svg");
}

.tdeeForm__info,
.tdeeForm__sex {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 80%;
	padding: 4vh 0 4vh 0;
}

.tdeeForm__info > input {
	display: flex;
	flex: 0.2;
	height: 100%;
	padding: 1rem 1.5rem;
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	border: 1px solid #eeeeee;
	resize: none;
}

.tdeeForm__option {
	display: flex;
	flex: 0.2;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	border: 1px solid #eeeeee;
	position: relative;
}

.tdeeForm__option > label {
	font-size: 1rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	width: 100%;
	padding: 1rem 1.5rem 1rem 2.5rem;
}

/* TDEE FORM OPTION CHECKBOX STYLING */
.tdeeForm__option > input[type="checkbox"]:not(:checked),
.tdeeForm__option > input[type="checkbox"]:checked {
	position: absolute;
	right: 1rem;
	opacity: 0.01;
}

/* General label styling */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label,
.tdeeForm__option > input[type="checkbox"]:checked + label {
	position: relative;
	cursor: pointer;
}

/* General label styling when checked*/
.tdeeForm__option > input[type="checkbox"]:checked + label {
	background-color: #f2f2f2;
}

/* General checkmark styling when NOT checked */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label:before,
.tdeeForm__option > input[type="checkbox"]:checked + label:before {
	content: "";
	position: absolute;
	right: 1rem;
	align-self: center;
	width: 1rem;
	height: 1rem;
	border: none;
	-webkit-transition: all 0.275s;
	transition: all 0.275s;
}

/* General checkmark styling when ISchecked */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label:after,
.tdeeForm__option > input[type="checkbox"]:checked + label:after {
	content: " \2713";
	font-size: 1rem;
	right: 1rem;
	align-self: center;
	position: absolute;
	color: #628989;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
}

/* Adding some animation to it */
.tdeeForm__option > input[type="checkbox"]:not(:checked) + label:after {
	opacity: 0;
	-webkit-transform: scale(0) rotate(45deg);
	transform: scale(0) rotate(45deg);
}

.tdeeForm__option > input[type="checkbox"]:checked + label:after {
	opacity: 1;
	-webkit-transform: scale(1) rotate(0);
	transform: scale(1) rotate(0);
}

.tdeeForm__activity {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-content: center;
	padding: 4vh 0 4vh 0;
	width: 90%;
}

/*-----Tdee results-----*/
.food__tdeeResult {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 80%;
	margin: 8vh 0 8vh 0;
}

.tdeeResult__item {
	display: flex;
	flex: 0.25;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 25vh;
	transition: .2s all;
}

.tdeeResult__item > h1 {
	font-size: 0.9rem;
	font-family: "Raleway", sans-serif;
	font-weight: bold;
	letter-spacing: 2px;
	line-height: 1.4;
	color: #01011d;
	text-align: center;
}

.tdeeResult__item > h1:last-of-type {
	font-size: 0.8rem;
	color: #8c8989;
}

.tdeeResult__item > h2 {
	font-size: 7rem;
	font-family: "Playfair Display", serif;
	letter-spacing: 2px;
	color: #c78567;
	cursor: pointer;

	-webkit-user-select: none; 
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}



/*-----Calculating macros, macros result section-----*/
.food__macrosResult {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: auto;
	background-image: url("../../assets/BG/FoodBG.svg");
	margin-top: 8vh;
	padding-bottom: 4vh;
}

.food__macrosResult > h1 {
	width: 80%;
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
	margin: 4vh 0 8vh 0;
}

.macrosResult__container {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 88%;
	height: 25vh;
	background-color: #ffffff;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
	margin: 2vh 0 2vh 0;
}

.macrosResult__name {
	display: flex;
	flex: 0.25;
	justify-content: center;
	align-items: center;
	height: 100%;
	background-color: #c78567;
}

.macrosResult__name > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #ffffff;
	text-align: center;
}

.macrosResult__info {
	display: flex;
	flex: 0.6;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 100%;
}

.macrosResult__infoHeading {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 70%;
}

.macrosResult__infoHeading > h1 {
	font-size: 1.6rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
}

.macrosResult__small {
	font-size: 50%;
}

.macrosResult__info > ul {
	width: 100%;
}

.macrosResult__info > ul > li {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 0.8rem;
	line-height: 1.6;
	color: #7e7b7b;
	margin-bottom: 0.4vh;
}

.macrosResult__result {
	display: flex;
	flex: 0.1;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
}

.macrosResult__result > h1 {
	font-size: 3rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #c78567;
	text-align: center;
}

/*-----Macros section color edit-----*/
.prot > .macrosResult__name {
	background-color: #527474;
}

.prot > .macrosResult__result > h1 {
	color: #527474;
}

.carbs > .macrosResult__name {
	background-color: #71a0a0;
}

.carbs > .macrosResult__result > h1,
.food__waterEdit {
	color: #71a0a0;
}

/*-----Water section-----*/
.food__water {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 9vh;
	margin-bottom: 1vh;
}

.food__water > h1 {
	font-size: 4rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
}

.food__water > p {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #7e7b7b;
	width: 80%;
	text-align: center;
	margin-top: 4vh;
}

.food__waterContainer {
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	width: 90%;
	margin: 8vh 0 2vh 0;
}

.waterContainer__info {
	display: flex;
	flex: 0.6;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: auto;
}

.waterContainer__info > h1 {
	font-size: 2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #00001d;
	text-align: center;
}

.waterContainer__info > ul {
	margin-left: 1rem;
	margin-top: 4vh;
}

.waterContainer__info > ul > li {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	font-size: 0.8rem;
	line-height: 1.6;
	color: #7e7b7b;
	margin-bottom: 0.4vh;
}

.waterContainerInfo__container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
	height: auto;
	margin-top: 6vh;
	margin-left: 1rem;
}

.waterContainerInfo__item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 5vw;
	width: auto;
}

.waterContainerInfo__item > img {
	margin-left: 2vw;
}

.waterContainerInfo__item > h1 {
	margin-top: 2vh;
	font-family: "Raleway", sans-serif;
	letter-spacing: 1px;
	font-size: 0.9rem;
	line-height: 1.2;
	color: #01011d;
	font-weight: bold;
	text-align: center;
}

.waterContainer__img {
	display: flex;
	flex: 0.3;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: auto;
}

/*-----Water text-----*/
.food__water > h2 {
	font-family: "Raleway", sans-serif;
	letter-spacing: 2px;
	line-height: 1.6;
	font-size: 0.8rem;
	color: #00001d;
	width: 70%;
	text-align: center;
	margin-bottom: 8vh;
}

/*-----Ending Quote-----*/
.food__end {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 6vh 0 6vh 0;
	width: 100%;
	background-image: url("../../assets/BG/FoodBG.svg");
}

.food__end > h1 {
	font-size: 2rem;
	font-family: "Raleway", serif;
	line-height: 1.4;
	letter-spacing: 4px;
	color: #00001d;
	text-align: center;
	width: 80%;
	margin-bottom: 2vh;
}

.food__end > h2 {
	font-size: 1.2rem;
	font-family: "Playfair Display", serif;
	line-height: 1.4;
	letter-spacing: 2px;
	color: #7e7b7b;
	text-align: right;
	width: 85%;
}

.foodEnd__edit {
	color: #c78567;
	font-weight: bold;
}

/*-----Media querrys-----*/
/* Only for quoteSection Heading */
@media (max-width: 1380px) {
	/**** Macros viramins grid ****/
	.microsVitamins__infoContainer,
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(5, auto);
	}

	/**** Macros result section ****/
	.macrosResult__infoHeading {
		width: 90%;
	}
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Charts container section ****/
	.food__chartsContainer > img {
		width: 18%;
		height: auto;
	}

	/**** Macros viramins and Minerals ****/
	.microsVitamins__img {
		position: absolute;
		right: -9%;
		top: -12%;
		transform: scale(0.5);
	}
	.microsVitamins__info,
	.microsMinerals__info {
		flex: 1;
	}
	.microsVitamins__info > ul,
	.microsMinerals__info > ul,
	.microsMinerals__infoContainer {
		width: 70%;
	}
	.microsVitamins__infoContainer,
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(7, auto);
	}
	.microsMinerals__info {
		align-items: flex-end;
	}
	.microsMinerals__info > h1 {
		width: 70%;
		text-align: left;
	}
	.microsMinerals__img {
		position: absolute;
		transform: scale(0.5);
		left: -12%;
		top: -12%;
	}

	/**** TDEE calculator ****/
	.tdeeResult__item > h2 {
		font-size: 5.5rem;
	}

	/**** Macros result section ****/
	.macrosResult__info > ul > li {
		font-size: 0.7rem;
	}
	.macrosResult__infoHeading > h1 {
		font-size: 1.4rem;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Macros Section ****/
	.food__macrosContainer {
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		width: 90%;
	}
	.macrosContainer__item {
		flex: auto;
		width: 40vw;
		margin-bottom: 5vh;
	}
	.macrosContainer__item:nth-child(2) {
		position: absolute;
		right: 0;
	}

	/**** Charts container section ****/
	.food__chartsHeading {
		width: 80%;
	}
	.food__chartsContainer > img {
		width: 30%;
		height: auto;
		margin-bottom: 8vh;
	}
	.food__chartsContainer > img:nth-child(2),
	.food__chartsContainer > img:nth-child(5) {
		margin-bottom: 0;
	}
	.food__chartsContainer {
		flex-wrap: wrap;
	}

	/**** Macros minerlas and viramins ****/
	.microsVitamins__img {
		transform: scale(0.4);
		right: -15%;
		top: -3%;
	}
	.microsMinerals__img {
		transform: scale(0.3);
		top: -20%;
		left: -18%;
	}
	.microsMinerals__info > h1:nth-of-type(2) {
		width: 100%;
	}
	.microsMinerals__infoContainer {
		width: 100%;
	}

	/**** TDEE calculator ****/
	.tdeeForm__activity {
		flex-wrap: wrap;
		padding: 4vh 0 0 0;
	}
	.tdeeForm__activity > .tdeeForm__option > label {
		width: 20vw;
	}
	.tdeeForm__activity > .tdeeForm__option {
		margin-bottom: 4vh;
	}
	.tdeeForm__info > input {
		width: 20%;
	}
	.tdeeResult__item > h2 {
		font-size: 5rem;
	}

	/**** Macros result section ****/
	.macrosResult__container {
		flex-direction: column;
		height: auto;
	}
	.macrosResult__name {
		width: 100%;
	}
	.macrosResult__name > h1 {
		margin: 2vh 0 2vh 0;
	}
	.macrosResult__info {
		margin: 4vh 0;
		max-width: 90%;
	}
	.macrosResult__info > ul > li {
		font-size: 0.8rem;
	}
	.macrosResult__infoHeading {
		justify-content: space-evenly;
		margin-bottom: 4vh;
	}
	.macrosResult__infoHeading > h1 {
		font-size: 1.6rem;
	}
	.macrosResult__result {
		margin-bottom: 4vh;
	}

	/**** Water section ****/
	.food__waterContainer,
	.waterContainer__info {
		flex-direction: column;
		align-items: center;
	}

	.waterContainer__img {
		display: none;
	}
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Macros Section ****/
	.macrosContainer__item:nth-child(2) {
		position: relative;
		align-self: flex-end;
	}

	/**** Charts container section ****/
	.food__chartsContainer > img {
		margin-bottom: 4vh;
	}

	/**** Macros minerals amd viramins ****/
	.microsVitamins__img,
	.microsMinerals__img {
		display: none;
	}
	.microsVitamins__info,
	.microsMinerals__info {
		align-items: center;
	}
	.microsMinerals__info > h1,
	.microsMinerals__info > h1:nth-of-type(2) {
		width: auto;
	}
	.microsVitamins__info > ul,
	.microsMinerals__info > ul {
		width: 100%;
	}
	.microsVitamins__infoContainer {
		grid-template-columns: repeat(6, auto);
	}
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(4, auto);
	}

	/**** TDEE calculator ****/
	.food__tdee > h1 {
		font-size: 3.5rem;
	}
	.tdeeForm__sex > .tdeeForm__option > label {
		width: 20vw;
	}
	.tdeeResult__item {
		height: 20vh;
	}
	.tdeeResult__item > h1 {
		font-size: 0.7rem;
	}
	.tdeeResult__item > h1:nth-of-type(2) {
		font-size: 0.6rem;
	}
	.tdeeResult__item > h2 {
		font-size: 3rem;
	}

	/**** Macros result section ****/
	.food__macrosResult > h1 {
		font-size: 3.5rem;
	}
	.macrosResult__infoHeading {
		flex-direction: column;
	}
	.macrosResult__infoHeading > h1:first-of-type {
		margin-bottom: 1vh;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Macros Section ****/
	.macrosContainer__item,
	.macrosContainer__item:nth-child(2) {
		align-self: center;
		width: 90%;
	}
	.food__macros > h1 {
		font-size: 2.8rem;
	}

	/**** Charts container section ****/
	.food__chartsHeading {
		width: 90%;
	}
	.chartsHeading__type > h1 {
		font-size: 0.8rem;
	}
	.food__chartsContainer {
		flex-direction: column;
	}
	.food__chartsContainer > img {
		width: 60%;
		height: auto;
		margin-bottom: 8vh;
	}
	.food__chartsContainer > img:last-child {
		margin-bottom: 0;
	}
	.food__chartsContainer {
		flex-wrap: nowrap;
	}

	/**** Macros viramins ****/
	.food__micros > h1 {
		font-size: 2.8rem;
	}
	.food__micros > p {
		font-size: 0.8rem;
		width: 80%;
	}
	.microsVitamins__infoContainer,
	.microsMinerals__infoContainer {
		grid-template-columns: repeat(3, auto);
		margin-left: -5%;
	}
	.microsVitamins__infoContainer > img,
	.microsMinerals__infoContainer > img {
		width: 80%;
		height: auto;
	}
	.microsVitamins__infoContainer > img:nth-child(2),
	.microsMinerals__infoContainer:first-of-type > img:nth-child(2) {
		width: 65%;
		height: auto;
	}
	.microsVitamins__info > ul > li,
	.microsMinerals__info > ul > li {
		margin-bottom: 1vh;
	}

	/**** TDEE calculator ****/
	.food__tdee > h1 {
		font-size: 2.4rem;
		text-align: center;
	}
	.tdeeForm__info,
	.tdeeForm__sex {
		flex-direction: column;
		width: 70%;
		padding: 4vh 0 0 0;
	}
	.tdeeForm__info > input,
	.tdeeForm__option {
		width: 100%;
		margin-bottom: 4vh;
	}
	.tdeeForm__activity {
		flex-wrap: nowrap;
		flex-direction: column;
		width: 70%;
	}
	.tdeeForm__option > label {
		width: 100% !important;
	}
	.food__tdeeResult {
		flex-direction: column;
		margin: 8vh 0 0 0;
	}
	.tdeeResult__item {
		margin-bottom: 6vh;
	}
	.tdeeResult__item > h2 {
		margin: 1vh 0 1vh 0;
	}

	/**** Macros result section ****/
	.food__macrosResult > h1 {
		font-size: 2.8rem;
	}
	.macrosResult__infoHeading > h1 {
		font-size: 1.2rem;
	}
	.macrosResult__info > ul > li {
		font-size: 0.7rem;
		margin: 1vh 0;
	}

	/**** Water section ****/
	.food__water > h1 {
		font-size: 2.8rem;
	}
	.waterContainer__info > h1 {
		font-size: 1.6rem;
	}
	.waterContainer__info > ul > li {
		margin-bottom: 1vh;
	}
	.waterContainerInfo__container {
		justify-content: space-evenly;
	}
	.waterContainerInfo__item {
		align-items: center;
		flex: 0.3;
	}
	.waterContainerInfo__item > img {
		width: 80%;
		height: auto;
	}
	.waterContainerInfo__item > h1 {
		font-size: 0.7rem;
	}

	/**** Food ending ****/
	.food__end > h1 {
		font-size: 1.6rem;
	}
	.food__end > h2 {
		font-size: 1rem;
	}
}
