/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url("../../assets/fonts/Raleway-VariableFont_wght.ttf");
}
@font-face {
	font-family: Playfair Display;
	src: url("../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

/*-----Global Componentns-----*/
.pageNotFound__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: calc(100vh - 152px);
	width: 100%;
}

.pageNotFound__container > h1 {
	font-size: 10rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	color: #00001d;
	margin-top: -20vh;
	line-height: 1.4;
}

.pageNotFound__container > h2 {
	font-size: 4rem;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	letter-spacing: 2px;
	color: #00001d;
}

.pageNotFound__border {
	border-bottom: 5px solid #628989;
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.pageNotFound__container > h2 {
		font-size: 3rem;
	}
	.pageNotFound__border {
		border-bottom: 2px solid #628989;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.pageNotFound__container > h1 {
		font-size: 8rem;
	}
	.pageNotFound__container > h2 {
		font-size: 1.5rem;
	}
}
