/*-----Fonts-----*/
@font-face {
	font-family: Raleway;
	src: url("../assets/fonts/Raleway-VariableFont_wght.ttf");
}
@font-face {
	font-family: Playfair Display;
	src: url("../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
}

/*-----Global Componentns-----*/
.container {
	transition: opacity 1s ease-in;
	opacity: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/*-----Navbar-----*/
.app__navbar {
	display: flex;
	flex-direction: row;
	width: 88%;
	justify-content: space-between;
	align-items: center;
	padding: 3rem 0 3rem 0;
}

.app__navbarLeft {
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.app__navbarLeft > h3 {
	color: black;
	font-weight: regular;
	font-family: "Playfair Display", serif;
	font-size: 2rem;
	padding: 0 0 0.5rem 1rem;
	align-self: flex-end;
}

.app__navbarLeft > img {
	height: 3.5rem;
}

.app__navbarColor {
	color: #628989;
}

.app__navbarRight > img {
	cursor: pointer;
	z-index: 100;
}

.app__navbarRight > img:active {
	transform: scale(0.9);
}

.app__navbarExpandedOpacity {
	transition: 0.5s ease-in;
	position: fixed;
	display: none;
	flex-direction: column;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 10;
	opacity: 0;
}

.app__navbarExpandedContainer {
	transition: 0.6s ease-in;
	align-self: flex-end;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 25rem;
	height: 100vh;
	border-left: 1.5rem solid #628989;
	background-color: #ffffff;
	transform: translateX(100%);
	z-index: 110;
}

.app__navbarExpandedItems {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 65%;
	height: 70%;
}

.app__navbarExpandedItems > img {
	padding: 0.5rem;
	margin-top: 2.5rem;
	align-self: flex-end;
	cursor: pointer;
	transform: scale(1);

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.app__navbarUl {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	height: 50%;
}

.app__navbarItem {
	font-size: 1.2rem;
	font-weight: bold;
	font-family: "Raleway", serif;
	letter-spacing: 2px;
	cursor: pointer;
	text-align: left;
	transform: scale(1);
	color: #00001d;
	text-decoration: none;
	padding: 20px;

	-webkit-user-select: none; 
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.app__navbarItem.active {
	color: #628989;
	background: none;
}

/*-----Navbar Animation-----*/
.app__navbarItem:hover,
.app__navbarExpandedItems > img:hover {
	transform: scale(1.1);
}

.app__navbarItem:active,
.app__navbarExpandedItems > img:active {
	transform: scale(0.9);
}

/*-----Happy footer-----*/
.home__footer {
	display: flex;
	flex: 1;
	flex-direction: row;
	width: 100%;
	height: 20vh;
	background-color: #01011d;
	z-index: 900;
}

.home__footerLeft {
	display: flex;
	flex: 0.5;
	background-image: url("../assets/BG/FooterBG.svg");
}

.home__footerRight {
	display: flex;
	flex: 0.5;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #01011d;
	z-index: 900;
}

.home__footerRight > div > h3 {
	font-family: "Raleway", sans-serif;
	font-size: 0.8rem;
	font-weight: bold;
	letter-spacing: 1px;
	text-align: center;
	color: white;
	text-transform: uppercase;
}

.home__footerRight > div > h1 {
	font-family: "Playfair Display", serif;
	color: #c78567;
	font-size: 1.8rem;
	margin-top: 5px;
	padding-bottom: 2rem;
	text-align: center;
}

.home__footerRight > div > h5 {
	font-family: "Playfair Display", serif;
	color: white;
	text-align: center;
	font-size: 0.6rem;
	letter-spacing: 1px;
}

.home__footerRight > div:nth-child(2) > h5 {
	margin-top: 4px;
}

/*-----Scroll Arrow-----*/
.app__arrow {
	position: fixed;
	bottom: 50px;
	right: -100px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center;
	transition: .4s;
	transform: scale(1);
	background-color: rgba(197, 197, 197, .0);
	cursor: pointer;
	z-index: 500;
}

.app__arrow > img {
	transform: translate(22%, 60%);
}

.app__arrow:hover {
	transform: scale(1.1);
	background-color: rgba(197, 197, 197, .4);
}

.app__arrow:active {
	transform: scale(.9);
}

/*-----Media querrys-----*/
/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	/**** Upper container ****/
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	/**** Upper container ****/
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	/**** Happy Footer ****/
	.home__footerLeft {
		display: none;
	}
	.home__footerRight {
		flex: 1;
		flex-direction: row;
		justify-content: space-around;
	}

	.home__footerRight > div:nth-child(2) {
		margin-top: -25px;
	}
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	/**** Navbar ****/
	.app__navbarExpandedContainer {
		width: calc(100vw - 1.5rem);
	}
	.app__navbarExpandedItems {
		width: 80%;
	}
	.app__navbarExpandedItems > ul {
		align-items: center;
	}

	/**** Happy Footer ****/
	.home__footer {
		height: 22vh;
	}
	.home__footerLeft {
		display: none;
	}
	.home__footerRight {
		flex-direction: column;
		justify-content: center;
	}
	.home__footerRight > div > h1 {
		padding-bottom: 1.2rem;
	}

	.app__arrow {
		bottom: 10px;
		transform: scale(.75) !important;
	}
}
