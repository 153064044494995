/*-----Rest of CSS is in FoodAndTraining.css-----*/
/*-----Welcome Section-----*/
.training__welcomeSectionText {
	background-image: url("../../assets/training/WelcomeImg.png");
}

/*-----Media querrys-----*/
/* Only for quoteSection Heading */
@media (max-width: 1380px) {
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/*Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}
